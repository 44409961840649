import React from 'react';
import { graphql } from 'gatsby';
import css from 'src/theme/css';
import Img from 'gatsby-image';
import Layout from 'src/components/Layout';
import { Flex, Grid } from 'src/components/FlexBox';
import ModuleWrapper from 'src/components/ModuleWrapper';
import TextStack from 'src/components/TextStack';
import Text from 'src/components/Text';
import SmartButton from 'src/components/SmartButton';

const Detail = ({ label, detail }) => (
  <Flex alignItems="center" gx={3}>
    <Text variant="caps">{label}</Text>
    <Text>{detail}</Text>
  </Flex>
);

export default function Integration({ data: { integration } }) {
  return (
    <Layout hasFixedNav={false}>
      <ModuleWrapper colorMode="light" isHero={true}>
        <Grid
          g={5}
          gridTemplateColumns={['auto', null, '1fr 1fr']}
          gridTemplateRows={['auto auto', null, 'auto']}
          alignItems="center"
          css={css({
            px: 'pageMargin',
            py: 'sectionMargin',
          })}
        >
          <TextStack alignItems="start">
            <SmartButton
              action="Link"
              variant="Arrow"
              label="All integrations"
              url="/integrations"
            />
            <Text variant="heading.xxl">{integration.title}</Text>
            {integration.longDescription && (
              <Text
                dangerouslySetInnerHTML={{
                  __html: integration.longDescription.childMarkdownRemark.html,
                }}
              />
            )}
            <Flex flexDirection="column" gy={2}>
              {integration.availability && (
                <Detail
                  label="Availability:"
                  detail={integration.availability}
                />
              )}
              {integration.builtBy && (
                <Detail label="Built by:" detail={integration.builtBy} />
              )}
              {integration.contact && (
                <Detail label="Contact:" detail={integration.contact} />
              )}
            </Flex>
            <SmartButton
              action="Link"
              variant="Primary"
              label="Learn more"
              url={integration.externalLink}
            />
          </TextStack>
          {integration.integrationImage && (
            <Img fluid={integration.integrationImage.fluid} />
          )}
        </Grid>
      </ModuleWrapper>
    </Layout>
  );
}

export const pageQuery = graphql`
  query IntegrationBySlug($slug: String!) {
    integration: contentfulAppIntegration(slug: { eq: $slug }) {
      id
      title
      slug
      externalLink
      category
      integrationDescription {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      longDescription {
        childMarkdownRemark {
          html
        }
      }
      integrationImage {
        fluid(maxWidth: 1400, quality: 80) {
          ...GatsbyContentfulFluid_noBase64
        }
      }
      hasIntegrationPage
      availability
      builtBy
      contact
    }
  }
`;
